body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    min-height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.gif_player {
    margin: 1rem 0;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    width: 80%;
}
.gif_player .play_button {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px dashed #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    left: 50%;
    opacity: 1;
    padding: 14px 10px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    transition: transform 0.4s, opacity 0.4s;
}
.gif_player .play_button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.7);
}
.gif_player .play_button::after {
    content: "GIF";
}
.gif_player.playing .play_button {
    transform: translate(-50%, -50%) scale(0) rotate(180deg);
    opacity: 0.5;
}
.gif_player img {
    max-width: 100%;
}
